.doc-header{

    position:fixed;
    top: 100px;
    height: 250px;
    width: 100vw;
    background-color: burlywood;
}
.doc-main{
    z-index: 999;
    height: 1900px;
    background-color: rgb(46, 27, 1);
}
