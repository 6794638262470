.bmc-slot-block {
  display: inline-block;
}
.bmc-slot {
  display: block;
  background-color: white;
  font-size: smaller;
  margin-top: 0.25rem;
  height: 2rem;
}
.bmc-slot-time {
  display: inline;
  padding-right: 2px;
}

.bmc-slot-owner {
  display: inline;
  padding-right: 15px;
}

.bmc-book-btn {
  display: inline;
  margin-right: 30px;
}
.bmc-court-head {
  background-color: #fe2e68;
  display: inline;
  position: relative;
  width: 235px;
  background-color: aliceblue;
}

.btn-action {
  width: 125px;
  padding: 1px;
  margin-top: 0.1rem;
  border-radius: 3px;
}
.btn-action-mine {
  background-color: #fe2e68;
  color: white;
  margin: 5px;
}
.btn-action-booked {
  background-color: grey;
  color: white;
}
.btn-action-free {
  background-color: #00e4a3;
  color: white;
}

.btn-flagged {
  border-color: #000000;
  border-width: 2px;
  color: #3b3b3b;
}

.calendar-container {
  position:sticky;
  display: inline-flex;
  width: 100vw;
  top: 50px;
  background-color: white;
  padding: 10px;
  height: 50px;
  z-index: 500;
}
.btn-submit-container {
  position:sticky;
  display: inline-flex;
  width: 100vw;
  top: 100px;
  background-color: white;
  padding: 10px;
  z-index: 500;
}
.btn-submit{
  width: 125px;
  padding: 1px;
  margin-top: 0.1rem;
  border-radius: 3px;
}

.btn-active{
  background-color: #00e4a3;
}
.btn-inactive{
  background-color:gray;
}
.schedule {
  position: relative;
  display: inline-flex;
  top: 5px;
  z-index: 0;
}
.courtSlots {
  display: flex;
  background-color: #fe2e68;
}
.bmc-booking-widget {
  padding-left: 5px;
  padding-right: 10px;
}
.btn-my-booking-widget {
  background-color: #fe2e68;
  color: white;
  display: block;
  width: 220px;
  height: 20px;
  padding-left: 5px;
  padding-right: 10px;
  margin: 10px;
  border-radius: 3px;
}
.recur-container{
  display: inline;
  margin-left: 20px;
}
.widget-flagged {
  border-color: #000000;
  border-width: 2px;
  color: #3b3b3b;
}
