

.bmc-nav{
    position: static;
    top:0px;
    display: flex;
    width: 100vw;
    
    background-color: darkslateblue;
    z-index: 999;

}

.bmc-nav-item{
    display: inline-block;
    font-size: 1rem;
    font-weight:500;
    color: white;
    padding: 15px;
}

.log-reg{
    padding-left: 50px;
} 