:root {
  --green: #34b27d;
  --yellow: #e5fe07;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
/* Responsive design @media queries */
/* For mobile phones: */
[class*="col-"] {
  float: left;
  padding: 15px;
  width: 100%;
}
.row::after {
  content: "";
  clear: both;
  display: table;
}

html {
  font-family: "Lucida Sans", sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

/* all children of nav element */
nav>* {
  color: #34b27d;
  font-size: xx-small;
  padding: 10px;
}

.bmc-header {
  top: 0;
  position: sticky;
  z-index: 999;
}

main {
  background-color: white;
}

.bmc-footer {
  position: absolute;
  bottom: 200vh;
  background-color: #737a61;
}

.logo {
  max-height: 50px;
  max-width: 50px;
}

.banner {
  height: 50px;
  color: #e5fe07;
}

.navbar {
display:inline-block;
height: auto;
}

/* For tablets: */
@media only screen and (min-width: 600px) {

  .col-s-1 {
    width: 8.33%;
  }

  .col-s-2 {
    width: 16.66%;
  }

  .col-s-3 {
    width: 25%;
  }

  .col-s-4 {
    width: 33.33%;
  }

  .col-s-5 {
    width: 41.66%;
  }

  .col-s-6 {
    width: 50%;
  }

  .col-s-7 {
    width: 58.33%;
  }

  .col-s-8 {
    width: 66.66%;
  }

  .col-s-9 {
    width: 75%;
  }

  .col-s-10 {
    width: 83.33%;
  }

  .col-s-11 {
    width: 91.66%;
  }

  .col-s-12 {
    width: 100%;
  }
}

/* For desktop: */
@media only screen and (min-width: 768px) {

  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }
}