
.main-image{
    width: 50vw;   
}
.logo{
    width:20vw;
    left:0
}
.home{
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}